import c from 'chalk';
import _omit from 'lodash/omit';
import pino from 'pino';
import { write } from './write';

interface Msg {
  msg: string;
  level: number;
  module?: string;
  time: number;
  [key: string]: unknown;
}

type StyleFunction = (text: string) => string;

const prefixColours: Record<string, StyleFunction> = {
  trace: c.white.bgCyanBright.bold,
  debug: c.white.bgBlueBright.bold,
  info: c.white.bgGreenBright.bold,
  warn: c.white.bgYellowBright.bold,
  error: c.white.bgRedBright.bold,
  fatal: c.white.bgRedBright.bold,
};

const moduleColours: Record<string, StyleFunction> = {
  trace: c.cyan.bold,
  debug: c.blue.bold,
  info: c.green.bold,
  warn: c.yellow.bold,
  error: c.red.bold,
  fatal: c.red.bold,
};

function isChrome() {
  return /Chrome/.test(navigator.userAgent);
}

function formatMsg(msg: Msg) {
  const level = pino.levels.labels[msg.level];
  const pcolour = prefixColours[level];
  const mcolour = moduleColours[level];

  if (!isChrome()) {
    return [` ${level.toUpperCase()} `, `${msg.module ? `(${msg.module}) ` : ''}`, `${msg.msg ?? ''}`].join('');
  }

  return [
    `${pcolour(` ${level.toUpperCase()} `)} `,
    `${msg.module ? mcolour(`${msg.module} `) : ''}`,
    `${msg.msg ?? ''}`,
  ].join('');
}

const logger = pino({
  level: 'trace',
  browser: {
    asObject: true,
    write: opts => {
      const o = opts as Msg;

      const ctx = _omit(o, ['msg', 'level', 'time', 'module']);
      const extra = [];
      if (Object.keys(ctx).length > 0) {
        extra.push(ctx);
      }

      const msg = formatMsg(o);

      let location = 'unknown';
      if (isChrome()) {
        // extract the location
        const e = new Error();
        if (e.stack) {
          const st = e.stack.split('\n')[4];
          const tokens = st.split(/\s+/);
          tokens.shift();
          location = tokens.join(' ');
        }
      }

      write(msg, location, ctx);
    },
  },
});

export function makeLogger(module: string) {
  return logger.child({ module });
}
