import {
  Divider,
  ListSubheader,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Stack as MuiStack,
  SxProps,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { ComponentType, MouseEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useFullscreen } from 'rooks';
import { PopoverPosition } from '@components/ng/popover/types';
import { getPopoverAnchorOrigin } from '@components/ng/popover/utils';

const LightDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.lightDivider,
}));

const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: '4px',
    padding: '12px',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    boxShadow:
      '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
  },
  '& .MuiList-root': {
    paddingY: 0,
  },
}));

const MenuItem = styled(MuiMenuItem)({
  paddingY: '6px',
  paddingX: '12px',
});

const Stack = styled(MuiStack)({
  gap: '8px',
  alignItems: 'center',
  lineHeight: '22px',
});

type IconType = ComponentType<{ width?: number | string; height?: number | string }>;

// custom render
export type PopoverMenuCustomItemT<Key extends string> = {
  id: Key;
  icon?: IconType;
  group?: string;
  divider?: boolean;
  selected?: boolean;
  disabled?: boolean;
  tooltip?: React.ReactNode;
};

export type PopoverMenuLabelledItemT<Key extends string> = PopoverMenuCustomItemT<Key> & {
  label: string | React.ReactNode;
};

// state
export type PopoverMenuState = {
  open: boolean;
};

export type PopoverMenuRenderProps = {
  state: PopoverMenuState;
  open: (event: MouseEvent<HTMLElement>) => void;
  close: () => void;
};

export interface PopoverMenuCustomProps<Key extends string, T extends PopoverMenuCustomItemT<Key>> {
  position: PopoverPosition;
  items: T[];
  onMenuItemClick: (item: Key) => void;
  getLabel: (item: T) => string;
  getIcon?: (item: T) => IconType;
  children: (props: PopoverMenuRenderProps) => ReactNode;
  additionalContent?: ReactNode;
  autoClose?: boolean;
  sx?: SxProps;
}

export interface PopoverMenuLabelledProps<Key extends string, T extends PopoverMenuLabelledItemT<Key>> {
  position: PopoverPosition;
  items: T[];
  onMenuItemClick: (item: Key) => void;
  getIcon?: (item: T) => IconType;
  children: (props: PopoverMenuRenderProps) => ReactNode;
  additionalContent?: ReactNode;
  autoClose?: boolean;
  sx?: SxProps;
}

export type PopoverMenuProps<Key extends string, T> =
  T extends PopoverMenuLabelledItemT<Key>
    ? PopoverMenuLabelledProps<Key, T>
    : T extends PopoverMenuCustomItemT<Key>
      ? PopoverMenuCustomProps<Key, T>
      : never;

export function PopoverMenu<Key extends string, T extends PopoverMenuCustomItemT<Key> | PopoverMenuLabelledItemT<Key>>({
  position = 'bottom_center',
  children,
  items,
  // render,
  onMenuItemClick,
  additionalContent,
  autoClose = true,
  sx,
  ...rest
}: PopoverMenuProps<Key, T>) {
  const { isFullscreenEnabled } = useFullscreen();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = useMemo(() => !!anchorEl, [anchorEl]);
  const state: PopoverMenuState = useMemo(() => ({ open }), [open]);

  const renderProps: PopoverMenuRenderProps = useMemo(
    () => ({
      open: handleOpen,
      close: handleClose,
      state,
    }),
    [handleClose, handleOpen, state],
  );

  const handleMenuItemClick = useCallback(
    (item: Key) => () => {
      if (autoClose) {
        setAnchorEl(null);
      }
      onMenuItemClick(item);
    },
    [onMenuItemClick, autoClose],
  );

  const renderMenuItem = useCallback(
    (item: PopoverMenuCustomItemT<Key> | PopoverMenuLabelledItemT<Key>) => {
      let label: string | React.ReactNode = 'unknown';
      if ('getLabel' in rest) {
        const getLabel = rest.getLabel as (item: PopoverMenuCustomItemT<Key>) => string;
        label = getLabel(item);
      } else if ('label' in item) {
        label = item.label;
      }

      let Icon = item.icon;
      if ('getIcon' in rest) {
        const getIcon = rest.getIcon as (item: PopoverMenuCustomItemT<Key>) => IconType;
        Icon = getIcon(item);
      }

      return (
        <Tooltip
          arrow
          title={item.tooltip}
          placement="left"
        >
          <Stack
            direction="row"
            width="100%"
            gap={1}
            color={item.disabled ? 'text.disabled' : 'text.primary'}
          >
            {Icon && (
              <Icon
                width={16}
                height={16}
              />
            )}
            <Typography>{label}</Typography>
          </Stack>
        </Tooltip>
      );
    },
    [rest],
  );

  type GroupedItem = {
    group: string;
    items: T[];
  };

  const groupedItems = items.reduce(
    (acc, item) => {
      if (item.group) {
        const group = acc.find(g => g.group === item.group);
        if (!group) {
          acc.push({ group: item.group, items: [item as T] });
        } else {
          group.items.push(item as T);
        }
      } else {
        const group = acc.find(g => g.group === '__ungrouped')!;
        group.items.push(item as T);
      }

      return acc;
    },
    [{ group: '__ungrouped', items: [] }] as GroupedItem[],
  );

  // the disablePortal prop should only change when the menu opens/closes
  const [fullscreen, setFullscreen] = useState(false);
  useEffect(() => {
    setFullscreen(isFullscreenEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      {children(renderProps)}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={getPopoverAnchorOrigin(position)}
        sx={sx}
        disablePortal={fullscreen}
      >
        {groupedItems
          .find(group => group.group === '__ungrouped')!
          .items.map(item => [
            <MenuItem
              key={`${item.id}__ungrouped`}
              onClick={!item.disabled ? handleMenuItemClick(item.id) : undefined}
              selected={item.selected}
              sx={{
                cursor: item.disabled ? 'default' : 'pointer',
              }}
            >
              {renderMenuItem(item)}
            </MenuItem>,
            item.divider ? <LightDivider /> : undefined,
          ])}
        {groupedItems
          .filter(group => group.group !== '__ungrouped')
          .map(group => [
            <ListSubheader
              key={group.group}
              sx={{
                lineHeight: '16px',
                marginTop: '8px',
                paddingLeft: '8px',
                paddingBottom: '2px',
                paddingTop: '4px',
              }}
            >
              <LightDivider textAlign="left">
                <Typography variant="labelBold">{group.group}</Typography>
              </LightDivider>
            </ListSubheader>,
            group.items.map(item => [
              <MenuItem
                onClick={!item.disabled ? handleMenuItemClick(item.id) : undefined}
                key={item.id}
                selected={item.selected}
                sx={{
                  cursor: item.disabled ? 'default' : 'pointer',
                }}
              >
                {renderMenuItem(item)}
              </MenuItem>,
              item.divider ? <LightDivider /> : undefined,
            ]),
          ])}
        {additionalContent}
      </Menu>
    </>
  );
}
