import { Box, Stack as MuiStack, Typography, styled } from '@mui/material';

export interface StateBoxProps {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
}

const Container = styled(MuiStack)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: `2px dashed ${theme.palette.secondary[400]}`,
  borderRadius: '24px',
  padding: theme.spacing(6, 8),
  width: '100%',
  maxWidth: '580px',
}));

const Content = styled(MuiStack)({
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
});

export function StateBox({ title, subtitle, children }: StateBoxProps) {
  return (
    <Container>
      <Content>
        <Typography
          variant="headingBold"
          sx={{
            color: 'text.primary',
            marginTop: '6px',
            marginBottom: '6px',
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="bodyRegular"
          sx={{
            color: 'text.secondary',
            textAlign: 'center',
          }}
        >
          {subtitle}
        </Typography>
      </Content>
      {children && (
        <Box
          sx={{
            marginTop: 3,
            alignSelf: 'center',
          }}
        >
          {children}
        </Box>
      )}
    </Container>
  );
}
