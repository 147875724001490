import { Button, Stack } from '@mui/material';
import { useNavigate } from '@router';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StateBox } from '@components/ng/statebox';

export default function NotFoundPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleClickBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleClickHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <StateBox
        title={t('error/404/primary')}
        subtitle={t('error/404/secondary')}
      >
        <Stack
          direction="row"
          spacing={2}
        >
          <Button
            variant="contained"
            onClick={handleClickBack}
          >
            {t('action/backtoprevious')}
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleClickHome}
          >
            {t('action/returnhome')}
          </Button>
        </Stack>
      </StateBox>
    </Stack>
  );
}
