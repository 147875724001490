import { Theme } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function TextField(theme: Theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          '& .clearButton': {
            opacity: '1 !important',
          },
        },
      },
    },
  };
}
