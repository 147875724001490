import { Theme } from '@mui/material/styles';
import { CalendarIcon, CloseFillIcon } from '@components/actionicons';

type IconProps = {
  height: number;
  width: number;
};

export default function DatePicker(theme: Theme) {
  return {
    MuiDatePicker: {
      defaultProps: {
        slots: {
          clearIcon: ({ height, width }: IconProps) => (
            <CloseFillIcon
              height={height}
              width={width}
            />
          ),
          openPickerIcon: ({ height, width }: IconProps) => (
            <CalendarIcon
              height={height}
              width={width}
            />
          ),
        },
        slotProps: {
          textField: {
            sx: {
              '& .MuiInputBase-adornedStart': {
                padding: '0px 0px 0px 0px !important',

                '& .MuiInputBase-inputAdornedStart': {
                  padding: '7px 0px 7px 0px !important',
                },
              },

              '& .MuiInputAdornment-positionEnd': {
                marginLeft: '-36px',
                marginRight: '0px',

                '& .MuiIconButton-root': {
                  margin: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                },
              },
            },
          },
          inputAdornment: {
            position: 'start',
            sx: {
              marginRight: 0,
              '& .MuiIconButton-root': {
                margin: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            },
          },
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 2],
                },
              },
            ],
          },
          clearIcon: {
            height: 16,
            width: 16,
            color: theme.palette.text.disabled,
          },
          openPickerIcon: {
            height: 16,
            width: 16,
          },
        },
      },
    },
  };
}
