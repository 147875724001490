import { gql } from '@api/deianira/generated/gql';

export const GET_USER_PROFILE = gql(/* GraphQL */ `
  query GetUserProfile {
    userProfile {
      id
      displayName
      userName
      locale
      timezone
      active
      name {
        givenName
        familyName
      }
      emails {
        value
        type
      }
      groups {
        id
        displayName
        provider
      }
      provider
    }
  }
`);

export const QUERY_USERS = gql(/* GraphQL */ `
  query GetUsers($page: Int, $limit: Int) {
    users(page: $page, limit: $limit) {
      count
      page
      totalCount
      totalPages
      data {
        id
        displayName
        userName
        locale
        timezone
        active
        name {
          givenName
          familyName
        }
        emails {
          value
          type
        }
        groups {
          id
          displayName
          provider
        }
        provider
      }
    }
  }
`);
