import { Box, Divider, Stack } from '@mui/material';
import { Children, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { CONTENT_MAX_WIDTH_MD, CONTENT_MAX_WIDTH_SM } from '@features/ng/constants';

const headingMargins = {
  xs: 2,
  xl: 8,
};

const contentMargins = {
  xs: 4,
  xl: 12,
};

export interface LayoutContentProps {
  sideMenu: React.ReactNode;
  content: React.ReactNode;
}

export function LayoutContent({ sideMenu, content }: LayoutContentProps) {
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [location.pathname]);

  return (
    <Stack
      direction="row"
      sx={{
        flexGrow: 1,
        paddingLeft: 2.5,
        paddingBottom: 8,
        paddingTop: 2.5,
        gap: 2.5,
        height: '100%',
      }}
    >
      <Box
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          paddingRight: 1,
          overflowY: 'auto',
        }}
      >
        {sideMenu}
      </Box>
      <Box
        ref={ref}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        {content}
      </Box>
    </Stack>
  );
}

export interface SubpageLayoutContentProps {
  header: React.ReactNode;
  children: React.ReactNode;
}

export function SubpageLayoutContent({ header, children }: SubpageLayoutContentProps) {
  return (
    <Stack
      direction="column"
      height="100%"
    >
      <Box
        sx={theme => ({
          position: 'sticky',
          top: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: headingMargins,
          paddingRight: headingMargins,
          backgroundColor: `${theme.palette.primary[50]} !important`,
          width: '100%',
          maxWidth: 1440,
          zIndex: 99,
        })}
      >
        {header}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          marginTop: 5,
          marginLeft: contentMargins,
          marginRight: contentMargins,
          paddingBottom: 4,
        }}
      >
        {children}
      </Box>
    </Stack>
  );
}

export interface SubpageContentProps {
  children: React.ReactNode;
}

export function SubpageContent({ children }: SubpageContentProps) {
  return (
    <Stack
      direction="column"
      divider={
        <Stack
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Divider
            sx={{
              width: '100%',
              maxWidth: {
                xs: CONTENT_MAX_WIDTH_SM,
                md: CONTENT_MAX_WIDTH_MD,
              },
            }}
          />
        </Stack>
      }
      gap={6}
    >
      {Children.toArray(children).filter(Boolean)}
    </Stack>
  );
}
